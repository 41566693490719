<script setup lang="ts">
  import {
    CloudUploadIcon,
    LockClosedIcon,
    RefreshIcon,
  } from '@heroicons/vue/outline'
  import { ref } from 'vue'
  import axios from 'axios'
  import { Form, Field, ErrorMessage } from 'vee-validate'

  document.title = 'Mindscraper Mobile - Services'

  const features = [
    {
      name: 'Media Planning and Buying Service',
      icon: CloudUploadIcon,
      description:
        'We provide a complete media buying service to optimize your brand exposure across all channels.',
    },
    {
      name: 'Targeted Digital Media Purchasing',
      icon: RefreshIcon,
      description:
        'Our digital media buying service uses sophisticated audience segmentation and real-time bidding technology to deliver your message to your target demographic. ',
    },
    {
      name: 'Multimedia Advertising Procurement',
      icon: LockClosedIcon,
      description:
        'Experience the ease of a one-stop solution for all your media buying needs.',
    },
  ]

  const isFormSubmitted = ref(false)
  const name = ref()
  const email = ref()
  const subject = ref()
  const message = ref()

  function validateEmptyField(value: any) {
    if (!value) {
      return 'This field is required'
    }

    return true
  }

  function validateEmail(value: any) {
    if (!value) {
      return 'This field is required'
    }

    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!regex.test(value)) {
      return 'This field must be a valid email'
    }

    return true
  }

  async function submitForm() {
    try {
      await axios({
        method: 'POST',
        url: 'https://wt.pstbck.net/items/placeholder_sites',
        data: {
          name: name.value,
          email: email.value,
          subject: subject.value,
          message: message.value,
          source: window.location.hostname,
        },
      })
      isFormSubmitted.value = true
    } catch (error) {
      console.log(error)
    }
  }
</script>

<template>
  <div class="relative bg-white py-16">
    <div
      class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"
    >
      <p
        class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl"
      >
        Services
      </p>

      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <component
                      :is="feature.icon"
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ feature.name }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ feature.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
