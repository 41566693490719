<script setup lang="ts">
  const currentYear = new Date().getFullYear()
</script>

<template>
  <footer class="bg-[#6366F1]">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <p class="mt-8 text-center text-base text-white">
        &copy; {{ currentYear }} Digital Advertising Kft.
      </p>
      <p class="text-center text-base text-white">
        Address: 11 Verebeshegy utca, Csomád, 2161, Hungary
      </p>
    </div>
  </footer>
</template>
